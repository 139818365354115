import React, { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, gql, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Table, Spin, message, Input, Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SearchOutlined, DollarOutlined, UnorderedListOutlined, BankOutlined, RobotOutlined } from '@ant-design/icons';
import { Modal } from 'antd/lib';

const GET_SCHEDULED_PAYMENTS_QUERY = gql`
  query GetScheduledPayments($page: Int!, $limit: Int!, $search: String) {
    scheduledPayments(page: $page, limit: $limit, search: $search) {
      items {
        id
        status
        amount
        user {
          firstName
          lastName
        }
        statement {
          paymentTargetDate
          dueDate
        }
        account {
          accountNumber
          paymentMethod
          provider {
            name
            payAsGuest
          }
        }
      }
      count
    }
  }
`;

const MAKE_PAYMENT_BY_AGENT_MUTATION = gql`
  mutation MakePaymentByAgent($scheduledPaymentId: Int!) {
    makePaymentByAgent(scheduledPaymentId: $scheduledPaymentId) {
      sessionId
      liveUrl
    }
  }
`;

interface ScheduledPayments {
  id: number;
  status: string;
  amount: number;
  user: {
    firstName: string;
    lastName: string;
  };
  statement: {
    paymentTargetDate: string;
    dueDate: string;
  };
  account: {
    accountNumber: string;
    paymentMethod: string;
    provider: {
      name: string;
      payAsGuest: boolean;
    };
  };
}

interface ScheduledPaymentsData {
  scheduledPayments: {
    items: ScheduledPayments[];
    count: number;
  };
}

interface ScheduledPaymentsVars {
  limit: number;
  page: number;
  search: string;
}

const ScheduledPaymentsList: React.FC = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [liveUrl, setLiveUrl] = useState<string>('');
  const [scheduledPayments, setScheduledPayments] = useState<ScheduledPayments[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [loadingPaymentId, setLoadingPaymentId] = useState<number | null>(null);
  const [fetchScheduledPayments] = useLazyQuery<
    ScheduledPaymentsData,
    ScheduledPaymentsVars
  >(GET_SCHEDULED_PAYMENTS_QUERY);
  const [makePaymentByAgent, { loading: paymentLoading }] = useMutation(
    MAKE_PAYMENT_BY_AGENT_MUTATION
  );
  const columns: ColumnsType<ScheduledPayments> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "First Name",
      dataIndex: ['user', 'firstName'],
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: ['user', 'lastName'],
      key: "lastName",
    },
    {
      title: "Payee",
      key: "payee",
      render: (_, record) => `${record?.account?.provider?.name} - ${record?.account?.accountNumber}`,
    },
    {
      title: "Payment Target Date",
      dataIndex: ['statement', 'paymentTargetDate'],
      key: "paymentTargetDate",
      render: (date: string) => {
        if (!date) return '-';
        return new Date(date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
    },
    {
      title: "Due Date",
      dataIndex: ['statement', 'dueDate'],
      key: "dueDate",
      render: (date: string) => {
        if (!date) return '-';
        return new Date(date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        });
      }
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(amount || 0);
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string) => status || 'Scheduled'
    },
    {
      title: "Action",
      key: "action",
      fixed: 'right',
      width: 220,
      render: (_, record) => (
        <span>
          <Button
            type="default"
            onClick={() => navigate(`/scheduled-payments/${record.id}`)}
          >
            <DollarOutlined />
          </Button>
          <Button type="default">
            <UnorderedListOutlined />
          </Button>
          {/* 
          if (payment['account_payment_method'].eql?(SilverBills::PAYMENT_METHOD_VIRTUAL_CARD) 
          && payment['statement_status'].eql?(SilverBills::STATUS_SCHEDULED)
          && ![SilverBills::STATUS_PAYMENT_INITIATED, SilverBills::STATUS_FUNDS_AVAILABLE].include?(payment['payment_status'])
          && !payment['statementBalance'].blank? && !payment['amountDue'].blank?) || payment['statement_status'].eql?(SilverBills::STATUS_INITIATE_PAYMENT)
        */}
          <Button danger type="default" disabled={!(record?.account?.paymentMethod === 'virtual_card' && record?.status === 'scheduled')}>
            <BankOutlined />
          </Button>
          <Button
            type="default"
            onClick={() => handleMakePaymentByAgent(record.id)}
            loading={loadingPaymentId === record.id}
            disabled={!record?.account?.provider?.payAsGuest}
          >
            <RobotOutlined />
          </Button>
        </span>
      ),
    }
  ];

  const handleMakePaymentByAgent = async (scheduledPaymentId: number) => {
    try {
      setLoadingPaymentId(scheduledPaymentId);

      const { data } = await makePaymentByAgent({
        variables: { scheduledPaymentId },
      });

      if (data?.makePaymentByAgent?.liveUrl) {
        // Open a new tab with the live URL
        setLiveUrl(data.makePaymentByAgent.liveUrl);
        setIsPreviewVisible(true);
      } else {
        message.warning('No live URL available');
      }
    } catch (error) {
      message.error('Failed to initiate payment by agent');
      console.error(error);
    } finally {
      setLoadingPaymentId(null);
    }
  };

  const loadScheduledPayments = useCallback(() => {
    setLoading(true);
    fetchScheduledPayments({
      variables: { limit: 10, page, search: debouncedSearch },
      fetchPolicy: "network-only", // Ensure a fresh query every time
      onCompleted: (data) => {
        setScheduledPayments(data.scheduledPayments.items);
        setTotal(data.scheduledPayments.count);
        setLoading(false);
      },
      onError: () => {
        message.error("Error loading unprocessed documents");
        setLoading(false);
      },
    });
  }, [fetchScheduledPayments, page, debouncedSearch]);

  useEffect(() => {
    loadScheduledPayments();
  }, [loadScheduledPayments]);

  useEffect(() => {
    const handler = setTimeout(() => {
      const cleanedSearch = search.replace(/\s+/g, ''); // Remove spaces
      if (cleanedSearch.length >= 3 || cleanedSearch.length === 0) {
        setDebouncedSearch(search);
      }
    }, 300); // 300ms delay

    return () => {
      clearTimeout(handler);
    };
  }, [search]);


  const handleTableChange = (pagination: any) => {
    setPage(pagination.current);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "left",
          marginBottom: 2,
        }}
      >
        <Input
          placeholder="Search"
          value={search}
          onChange={handleSearchChange}
          style={{ marginBottom: 20, width: 200, marginRight: 10 }}
          prefix={<SearchOutlined />}
        />
      </div>

      <Modal
        title="Agent View"
        open={isPreviewVisible}
        onCancel={() => setIsPreviewVisible(false)}
        width="80%"
        footer={null}
      >
        <iframe
          src={liveUrl}
          style={{ width: '100%', height: '80vh', border: 'none' }}
          title="Document Preview"
        />
      </Modal>

      <Spin spinning={loading}>
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <Table
            columns={columns}
            dataSource={scheduledPayments}
            pagination={{ current: page, pageSize: 10, total }}
            onChange={handleTableChange}
            rowKey="id"
            scroll={{ x: 'max-content' }}
          />
        </div>
      </Spin>
    </>
  );
};

export default ScheduledPaymentsList;
