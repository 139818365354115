

import React from 'react';
import { useParams } from 'react-router-dom';

const ScheduledPayment: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <>
      <h1>Scheduled Payment: {id}</h1>
    </>
  );
};

export default ScheduledPayment;