

import React, { useState, useEffect } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Input, message, Switch, Space, Typography, Spin } from 'antd';

const CREATE_PAYMENT_BATCH = gql`
  mutation CreatePaymentBatch($amount: Float!) {
    createPaymentBatch(amount: $amount)
  }
`;

const GET_DISABLE_PAYMENT_BATCHES = gql`
  query DisablePaymentBatches {
    disablePaymentBatches
  }
`;

const UPDATE_DISABLE_PAYMENT_BATCHES = gql`
  mutation UpdateDisablePaymentBatches($disabled: Boolean!) {
    updateDisablePaymentBatches(
      disabled: $disabled
    )
  }
`;

const Transfers: React.FC = () => {
  const [amount, setAmount] = useState<string>('');
  const [createPaymentBatch, { loading }] = useMutation(CREATE_PAYMENT_BATCH);
  const { data, loading: queryLoading } = useQuery(GET_DISABLE_PAYMENT_BATCHES, {
    fetchPolicy: 'network-only' // This ensures fresh data is fetched each time
  });
  const [updateDisablePaymentBatches] = useMutation(UPDATE_DISABLE_PAYMENT_BATCHES);

  const [autoBatchesDisabled, setAutoBatchesDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setAutoBatchesDisabled(data.disablePaymentBatches);
    }
  }, [data]);

  const handleTransfer = async () => {
    try {
      await createPaymentBatch({
        variables: {
          amount: parseFloat(amount)
        }
      });
      setAmount('');
      message.success(`Successfully transferred $${amount} to Corpay`);
    } catch (error) {
      console.error('Transfer failed:', error);
      message.error('Failed to transfer to Corpay');
    }
  };

  const handleToggleAutoBatches = async (checked: boolean) => {
    try {
      await updateDisablePaymentBatches({
        variables: {
          disabled: !checked
        }
      });
      setAutoBatchesDisabled(!checked);
      message.success(`Automatic batches ${checked ? 'enabled' : 'disabled'}`);
    } catch (error) {
      console.error('Failed to update automatic batches setting:', error);
      message.error('Failed to update automatic batches setting');
    }
  };

  return (
    <>
      {queryLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Spin size="large" tip="Loading..." />
        </div>
      ) : (
        <>
          <h1>Transfers</h1>
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Space align="center">
              <Typography.Text>Automatic Batches:</Typography.Text>
              <Switch
                checked={!autoBatchesDisabled}
                onChange={handleToggleAutoBatches}
                loading={queryLoading}
              />
              <Typography.Text type="secondary">
                {!autoBatchesDisabled ? 'Enabled' : 'Disabled'}
              </Typography.Text>
            </Space>

            <div>
              <Input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter amount"
                step="0.01"
                min={0}
                style={{ width: 200, marginRight: 8 }}
              />
              <Button
                onClick={handleTransfer}
                disabled={!amount || isNaN(parseFloat(amount)) || loading}
                loading={loading}
              >
                Transfer to Corpay
              </Button>
            </div>
          </Space>
        </>
      )}
    </>
  );
};

export default Transfers;