

import React from 'react';
import ScheduledPaymentsList from './ScheduledPaymentsList';

const ScheduledPayments: React.FC = () => {
  return (
    <>
      <h1>Scheduled Payments</h1>
      <ScheduledPaymentsList />
    </>
  );
};

export default ScheduledPayments;